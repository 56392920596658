import React, { useEffect, useState } from "react";
import GoBackButton from "shared/ui/back-button";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import Button from "shared/ui/button";
import DocumentRow from "../components/document-row";
import { useStore } from "zustand";
import { sessionModel } from "entities/session";
import { onboardModel } from "entities/onboard";
import { ResponseCodes, sircapApi } from "shared/api/sircap";
import { toast } from "react-toastify";
import {
  EntityDocumentsEnum,
  onboardStore,
} from "entities/onboard/model/onboardModel";
import { useTranslation } from "react-i18next";
import GoBack from "../../../assets/GoBackApp.svg";
import { sessionStore } from "entities/session/model/sessionModel";
import { accountStore } from "entities/account";
import Loading from "widgets/loading";
import { getMLValue } from "helpers/functions";

export const SignContractPage = () => {
  const [isSubmit, setIsSubmit] = useState(false);
  const navigate = useNavigate();
  const { syncUser } = useStore(onboardStore);
  const { kyb, synckyb, isLoading } = useStore(accountStore);
  const [isValid, setIsValid] = useState(false);
  const { t: a } = useTranslation("app");
  const { i18n, t } = useTranslation("onboarding-auth");
  const { user, addUser } = useStore(sessionStore);
  const [adressValue, setAdressValue] = useState("");

  // useEffect(() => {
  //   setLink(PATH_LIST.createPassword);

  //   return () => setLink("");
  // }, []);

  const onSubmit = async () => {
    setIsSubmit(true);
    const resp = await sircapApi.onboarding.sign({
      id: user!.id,
      personal_address: adressValue,
    });
    if (resp.error) {
      switch (resp.error.code) {
        case ResponseCodes.ServerError:
          toast.error("Server Error");
          break;

        case ResponseCodes.Forbidden:
          toast.error("For continue you should login for sync data");
          navigate(PATH_LIST.loginPassword);
          break;

        default:
          break;
      }
    } else {
      const kyb = resp.data.data;
      synckyb(kyb);

      navigate(PATH_LIST.account, {
        state: { verificationRequestModal: true },
      });
    }
    setIsSubmit(false);
  };

  useEffect(() => {
    const documents = kyb?.documents;
    if (!documents) return;
    for (const [key, value] of Object.entries(documents)) {
      // @ts-ignore
      const isOptional = value.is_optional;
      if (isOptional) continue;
      setIsValid(false);
      return;
    }
    if (!adressValue) {
      setIsValid(false);
      return;
    }

    setIsValid(true);
  }, [kyb, adressValue]);
  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="sign-business-contract">
      <div className="sign-business-contract-header">
        <img
          className="go-back-portfolio"
          onClick={() => navigate(-1)}
          src={GoBack}
          alt="Go Back"
        />
        <p>{a("verification")}</p>
      </div>

      <div className="sign-business-contract-main">
        <h1>{t("signContract")}</h1>

        <div className="warnings">
          <ul>
            <li>{t("documentsUpToDate")}</li>
            <li>{t("maxFileSizeIs21mb")}</li>
          </ul>
        </div>

        <div className="documents">
          {Object.entries(kyb?.documents || {}).map(([key, o]: any, i) => (
            <DocumentRow
              file={o.is_multiple ? o.file || [] : o.file || ""}
              index={i + 1}
              text={getMLValue(o.text, i18n.language)}
              isMultiple={o.is_multiple}
              isOptional={o.is_optional}
              type={key}
            />
          ))}

          <div className="personal-addres-row">
            <p>
              <span>{Object.entries(kyb?.documents).length + 1}.</span>{" "}
              {t("enterPersonalAddress")}
            </p>
            <input
              style={{
                color: "#4E5566",
                fontFamily: "",
                fontSize: 16,
                padding: 16,
                borderRadius: 8,
                borderWidth: 1,
                borderColor: "#BFC4CE",
              }}
              type="text"
              onChange={(e) => {
                setAdressValue(e.target.value);
              }}
              placeholder={t("enterPersonalAddress")}
            />
          </div>

          <div className="confirmCorrectness">
            <h2>{t("signature")}</h2>
            <h3>{t("confirmingCorrectnessOfFilledData")}</h3>
            <Button
              className="signContractBtn"
              children={t("confirming")}
              variant="dark"
              disabled={!isValid || isSubmit}
              type="button"
              onClick={onSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

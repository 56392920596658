import React, { useRef, MouseEvent, FC, useState } from "react";
import "./style.scss";
import Stories from "react-insta-stories";
import Close from "../../../assets/CloseStories.svg";
import { useStore } from "zustand";
import { dashboardStore } from "entities/dashboard";
import { useTranslation } from "react-i18next";
import AssetIcon from "../components/asset-icon";
import { getMLValue, getStoryImgUrl } from "helpers/functions";
import VectorRight from "../../../assets/VectorRight.svg";
import { useNavigate } from "react-router-dom";

type PropsType = {
  onAllStoriesEnd?: () => void;
  currentIndex: any;
  whatToBuyStories?: boolean;
  data?: any;
};
const StoriesModal: FC<PropsType> = ({
  onAllStoriesEnd,
  currentIndex,
  whatToBuyStories,
  data,
}) => {
  const { t, i18n } = useTranslation("app");
  const asset = data?.asset;
  const description = data?.info?.description || "";
  const color = data?.info?.color || "";
  const { stories } = useStore(dashboardStore);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [imageUrl, setImageUrl] = useState("");
  const [brokenImage, setBrokenImage] = useState("");
  let isDragging = false;
  let startPosition = 0;
  let deltaX = 0;

  const navigate = useNavigate();

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    isDragging = true;
    startPosition = e.pageX - (containerRef.current?.scrollLeft || 0);
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isDragging) return;
    e.preventDefault();
    const scrollX = e.pageX - startPosition;
    if (containerRef.current) {
      containerRef.current.scrollLeft = -scrollX;
    }
    deltaX = scrollX;
  };

  const handleMouseUp = () => {
    isDragging = false;
    if (onAllStoriesEnd && Math.abs(deltaX) > 10) {
      onAllStoriesEnd();
    }
    if (Math.abs(deltaX) < 5) {
      // Add a threshold to differentiate clicks from drags
      // Handle click action here if needed
      // console.log("W");
    }
  };

  return (
    <div
      className="stories-container"
      ref={containerRef}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <Stories
        stories={
          !whatToBuyStories
            ? stories.map((story, i) => {
                return {
                  content: () => (
                    <div
                      className={`stories-main`}
                      style={{
                        backgroundImage: `url(${getStoryImgUrl(
                          story.use_multi_lang_images
                            ? getMLValue(story.content_images, i18n.language)
                            : story.content_image
                        )})`,
                      }}
                    >
                      {story?.web_action_link && (
                        <button
                          onClick={() => {
                            setTimeout(() => {
                              navigate(
                                story.web_action_link!,
                                story?.web_action_params || {}
                              );
                            }, 200);
                          }}
                          className="stories-link-btn"
                        >
                          <img src={VectorRight} alt="open link" />
                          {getMLValue(
                            story?.action_link_text || {},
                            i18n.language
                          ) || t("openStoryLink")}
                        </button>
                      )}
                      <img
                        onClick={onAllStoriesEnd}
                        className="close-stories"
                        src={Close}
                        alt="close"
                      />
                      <div className="text-stories-block">
                        {story.use_content ? (
                          <div
                            className="story-text-content"
                            dangerouslySetInnerHTML={{
                              __html: getMLValue(story.content, i18n.language),
                            }}
                          ></div>
                        ) : (
                          <>
                            <p>{getMLValue(story.title, i18n.language)}</p>
                            <span>{getMLValue(story.text, i18n.language)}</span>
                          </>
                        )}
                        {getMLValue(story?.link || {}, i18n.language) && (
                          <a
                            href={getMLValue(story.link, i18n.language)}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {getMLValue(
                              story?.link_text || {},
                              i18n.language
                            ) || t("disclaimer")}
                          </a>
                        )}
                      </div>
                    </div>
                  ),
                };
              })
            : [
                {
                  content: () => (
                    <div className="stories-main asset-stories-main">
                      <img
                        onClick={onAllStoriesEnd}
                        className="close-stories"
                        src={Close}
                        alt="close"
                      />
                      <div
                        className="asset-story-header"
                        style={{
                          background: brokenImage
                            ? "linear-gradient(130.24deg, rgba(248, 211, 235, 0.7) -13.54%, rgba(187, 207, 250, 0.7) 113.9%)"
                            : "",
                        }}
                      >
                        {(imageUrl || color) && (
                          <div
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                              ...(color
                                ? {
                                    backgroundColor: color,
                                    filter: "brightness(0.9)",
                                  }
                                : {
                                    backgroundImage: `url(${imageUrl})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    filter:
                                      "blur(22px) brightness(0.6) contrast(0.9) saturate(1.5)",
                                    transform: "scale(8)",
                                    backgroundRepeat: "no-repeat",
                                  }),
                              objectFit: "cover",
                              zIndex: 5,
                            }}
                          />
                        )}
                        <div className="story-asset">
                          <AssetIcon
                            asset={asset}
                            onGetImageUrl={setImageUrl}
                            onGetBrokenImage={setBrokenImage}
                          />
                          <div className="story-asset-name">
                            <p>
                              {asset.symbol} | {asset.name}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <p>{companyName || ""} </p> */}
                      {/* <p></p> */}
                      <span className="wtb-story-description">
                        {description || ""}
                      </span>
                    </div>
                  ),
                },
              ]
        }
        preventDefault={whatToBuyStories ? true : false}
        currentIndex={currentIndex}
        defaultInterval={whatToBuyStories ? 25000 : 15000}
        onAllStoriesEnd={onAllStoriesEnd}
        // preventDefault={false}
        preloadCount={1}
        width={"100%"}
        height={"100%"}
        keyboardNavigation={true}
        progressContainerStyles={{
          paddingLeft: whatToBuyStories ? "18px" : "24px",
          paddingRight: whatToBuyStories ? "18px" : "24px",
        }}
        progressWrapperStyles={{
          width: "100%",
          marginTop: whatToBuyStories ? "16px" : "24px",
          overflow: "hidden",
          background: "#FFFFFF1A",
        }}
        progressStyles={{
          width: "100%",
          background: "#FFFFFF",
        }}
      />
    </div>
  );
};

export default StoriesModal;

import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import ReplenishmentDetails from "../components/replenishment-details";
import Currency from "../components/deposit-currency";
import { CurrencyModal } from "../components/currency-modal";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { accountStore, useDepositInfo } from "entities/account";
import { useStore } from "zustand";
import { CURRENCY_FLAGS } from "static/currencies";
import Loading from "widgets/loading";
import {
  useUserContract,
  useUserName,
} from "entities/session/model/sessionModel";
import { useTranslation } from "react-i18next";
import Button from "shared/ui/button";
import Modal from "widgets/auth/modal";

export const DepositPage: FC = () => {
  const navigate = useNavigate();
  const [currencyModal, setCurrencyModal] = useState<
    null | "bank" | "currency"
  >(null);
  const { t } = useTranslation("app");
  const {
    isLoading,
    depositInfo: depositAccounts,
    getDepositInfo,
    selectedDepositCurrency,
    selectCurrency,
  } = useStore(accountStore);

  const [selectedBank, setSelectedBank] = useState<number | null>(null);
  const [depositForm, setDepositForm] = useState(false);
  const depositInfo = useDepositInfo(selectedDepositCurrency, selectedBank);
  const currencies = new Set(depositAccounts?.map((v) => v.currency.code));
  const userName = useUserName();
  const contract = useUserContract();

  const handleNavigate = () => {
    navigate(-1);
  };

  useEffect(() => {
    getDepositInfo();
  }, []);

  useBlockScrollbar(!!currencyModal, [currencyModal]);

  if (!depositInfo || isLoading) {
    return (
      <div className="deposit">
        <div className="deposit-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <p>{t("deposit")}</p>
        </div>

        {isLoading ? <Loading /> : <p>{t("noData")}</p>}
      </div>
    );
  }

  return (
    <div className="deposit">
      <div className="deposit-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{t("deposit")}</p>
      </div>

      <div className="deposit-main">
        <p className="deposit-title">{t("replenishmentDetails")}</p>
        <ReplenishmentDetails
          nameOfDetail={t("fullName")}
          detail={userName as string}
        />

        <Currency
          image={CURRENCY_FLAGS[selectedDepositCurrency]}
          currency={selectedDepositCurrency}
          onClick={() => setCurrencyModal("currency")}
        />
        <Currency
          image={depositInfo.info_source.image}
          onClick={() => setCurrencyModal("bank")}
          bank={depositInfo.info_source.name}
        />

        <ReplenishmentDetails
          nameOfDetail={t("accountNumber")}
          detail={depositInfo.accountNumber}
        />
        <ReplenishmentDetails nameOfDetail={t("contract")} detail={contract} />
        <ReplenishmentDetails
          nameOfDetail={t("recipient")}
          detail={depositInfo.recipient}
        />

        <ReplenishmentDetails
          nameOfDetail={t("purpose")}
          detail={t("deposit-purpose", {
            contract: contract,
          })}
        />

        {/* <div className="inform-us"></div>
        <div className="btn-wrapper">
          <Button
            children={t("I made deposite")}
            variant="dark"
            onClick={() => setDepositForm(true)}
          />
        </div> */}
      </div>

      {currencyModal && (
        <CurrencyModal
          onClose={() => setCurrencyModal(null)}
          onCurrencyChange={
            currencyModal === "bank"
              ? setSelectedBank
              : (v) => {
                  setSelectedBank(null);
                  selectCurrency("deposit")(v);
                }
          }
          selectedCurrency={selectedDepositCurrency}
          selectedBank={selectedBank}
          currencies={Array.from(currencies)}
          type={currencyModal}
          banks={
            currencyModal === "bank"
              ? depositAccounts
                  .filter((d) => d.currency.code === selectedDepositCurrency)
                  .map((d) => d.info_source)
              : undefined
          }
        />
      )}

      {/* {depositForm && (
        <Modal
          customModal="modalLogOut verificationRequestModal"
          onClick={() => setDepositForm(false)}
          onHide={() => setDepositForm(false)}
          title={t("verificationRequest")}
          text={t("verificationRequestSent")}
          showSecondBtn={false}
          customOutlet="customOutlet"
        />
      )} */}
    </div>
  );
};

import React, { FC, useEffect, useState } from "react";
import "./style.scss";
import FileOploudComplete from "../../../../assets/FileOploudComplete.svg";
import RemoveFile from "../../../../assets/RemoveFile.svg";
import OploudFailed from "../../../../assets/OploudFailed.svg";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import { boolean } from "yup";
import { onboardModel } from "entities/onboard";
import { useStore } from "zustand";
import { sircapApi } from "shared/api/sircap";
import { ContentType } from "shared/api/http-client";
import { EntityDocumentsEnum } from "entities/onboard/model/onboardModel";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { accountStore } from "entities/account";

type UploadFileStatus = "default" | "loading" | "complete" | "failed" | "error";

type PropsType = {
  isOptional: boolean;
  id: string;
  file: string | string[];
  type: string;
};

export const UploadFile: FC<PropsType> = ({
  isOptional,
  id,
  file: uploadedFile,
  type,
}) => {
  const { t } = useTranslation("onboarding-auth");
  const [status, setStatus] = useState<UploadFileStatus>("default");
  const [checked, setChecked] = useState(false);
  const inputRef = React.useRef(null);
  const [drag, setDrag] = useState(false);
  const [file, setFile] = useState<any>(null);
  const {
    id: userId,
    documents,
    setSome,
    additionalDocument,
    dontHaveLicense,
  } = useStore(onboardModel.onboardStore);
  const { synckyb } = useStore(accountStore);

  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (checked) return;
    if (e.type === "dragenter" || e.type === "dragover") {
      setDrag(true);
    } else if (e.type === "dragleave") {
      setDrag(false);
    }
  };

  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (checked) return;
    setDrag(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      uploadFile(e.dataTransfer.files[0]);
    } else {
      setStatus("default");
    }
  };

  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      uploadFile(e.target.files[0]);
    } else {
      setStatus("default");
    }
  };

  const uploadFile = async (file: File) => {
    setStatus("loading");
    if (file.size > 21 * 1024 * 1024) {
      setStatus("failed");
      setFile(null);
      return;
    }
    try {
      const resp = await sircapApi.onboarding.entityDocument(
        {
          type: type,
          index: id,
          entity: file,
        },
        {
          type: ContentType.FormData,
          format: "formData",
        }
      );
      if (resp.error) {
        setFile(null);
        setStatus("error");
      } else {
        const kyb = resp.data.data;
        // setFile(fileName);
        synckyb(kyb);
        setStatus("default");
        // setStatus('complete');
      }
    } catch (error) {
      toast.error("Upload Error");
    }
  };

  const onFileRemove = async () => {
    try {
      setStatus("loading");
      const resp = await sircapApi.onboarding.deleteEntityDocument(
        {},
        {
          type: type,
          file: uploadedFile,
          entity: {},
        }
      );
      if (resp.error) {
        setStatus("complete");
        // setFile('');
        // setStatus('error');
      } else {
        // @ts-ignore
        const kyb = resp.data.data;
        // setFile(fileName);
        synckyb(kyb);
        setStatus("default");
      }
    } catch (error) {
      setStatus("complete");
      // setStatus('error');
      // toast.error('Upload Error');
    }
  };

  useEffect(() => {
    if (status !== "default") {
      setChecked(false);
    }
  }, [status]);

  useEffect(() => {
    if (uploadedFile) {
      setStatus("complete");
    }

    // if (addDocName) {
    //   if (additionalDocument.filename) {
    //     setStatus("complete");
    //   }
    // }
  }, [uploadedFile]);

  return (
    <div className="fileArea">
      {/* {addDocName && (
        <input
          className="addDocName"
          type="text"
          onChange={(e) =>
            setSome({
              additionalDocument: {
                ...additionalDocument,
                name: e.target.value,
              },
            })
          }
          value={additionalDocument.name}
          placeholder="Название документа"
        />
      )} */}
      <>
        {status === "default" && (
          <div
            className={
              id === EntityDocumentsEnum.LicenseCopies && dontHaveLicense
                ? "oploudBlock oploudFileBox oploudFileBoxDisabled"
                : "oploudBlock oploudFileBox"
            }
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          >
            <label className={drag === true ? "drageFile" : ""} htmlFor={id}>
              {drag === true ? t("dropHere") : t("uploadFile")}
            </label>
            <input
              ref={inputRef}
              id={id}
              // disabled={
              //   id === EntityDocumentsEnum.LicenseCopies && dontHaveLicense
              // }
              onChange={handleChange}
              type="file"
              name="entity"
              placeholder={t("uploadFile")}
            />
          </div>
        )}

        {status === "loading" && (
          <div className="oploudBlock oploudFileLoading">
            <p>{t("loading")}</p>

            <div id="loading">
              <div className="hold left">
                <div className="fill"></div>
              </div>
              <div className="outer-shadow"></div>
              <div className="inner-shadow"></div>
              <div className="hold right">
                <div className="fill"></div>
              </div>
            </div>
          </div>
        )}

        {status === "complete" && (
          <div className="oploudBlock oploudFileComplete">
            <p>{uploadedFile || file?.name || ""}</p>

            <img
              className="oploudComplete"
              src={FileOploudComplete}
              alt="FileOploudComplete"
            />

            <img
              className="removeFile"
              onClick={onFileRemove}
              src={RemoveFile}
              alt="RemoveFile"
            />
          </div>
        )}

        {status === "failed" && (
          <div className="oploudBlock oploudFileFailed">
            <p>{t("invalid")}</p>

            <div className="invalidIcons">
              <img
                className="oploudFailed"
                src={OploudFailed}
                alt="OploudFailed"
              />
              <img
                className="removeFile"
                onClick={onFileRemove}
                src={RemoveFile}
                alt="RemoveFile"
              />
            </div>
          </div>
        )}

        {status === "error" && (
          <div className="oploudBlock oploudFileFailed">
            <p>Error</p>

            <div className="invalidIcons">
              <img
                className="oploudFailed"
                src={OploudFailed}
                alt="OploudFailed"
              />
              <img
                className="removeFile"
                onClick={onFileRemove}
                src={RemoveFile}
                alt="RemoveFile"
              />
            </div>
          </div>
        )}
      </>
      {/* {status === "default" && (
        <p className="oploudFailedDefault">{t("maxFileSizeIs21mb")}</p>
      )} */}
      {status === "failed" && (
        <p className="oploudFailedValidation">{t("fileExceedSizeLimit")}</p>
      )}
      {/* {license && (
        <TermsCheckbox
          isChecked={dontHaveLicense}
          className={
            status === "default"
              ? "checkboxFile"
              : "checkboxFile checkboxFileDisabled"
          }
          disabled={status === "default" ? false : true}
          term={t("iDontHaveLicense")}
          htmlForId="15"
          onChange={() => setSome({ dontHaveLicense: !dontHaveLicense })}
        />
      )} */}
    </div>
  );
};

import React, { useState } from "react";
import Close from "../../../assets/Close.svg";
import "./style.scss";
import ModalButton from "../components/modal-btn";
import AuthCode from "react-auth-code-input";
import { useTranslation } from "react-i18next";

type PropsType = {
  title: string;
  text: string;
  btnText?: string;
  btnText2?: string;
  showSecondBtn: boolean;
  onClick: any;
  onClickSecondBtn?: () => void;
  onHide: any;
  closeIcon?: boolean;
  customModal?: string;
  customOutlet?: string;
  confirmDeal?: boolean;
  isLoading?: boolean;
  dealError?: boolean;
  inputField?: boolean;
};

const Modal: React.FC<PropsType> = ({
  title,
  text,
  btnText,
  btnText2,
  showSecondBtn,
  onClick,
  onClickSecondBtn,
  onHide,
  closeIcon = true,
  customModal,
  customOutlet,
  confirmDeal = false,
  isLoading = false,
  dealError = false,
  inputField,
}) => {
  const [pin, setPin] = useState("");
  const { t: y } = useTranslation("yup");

  const handleOnChange = (newValue: string) => {
    setPin(newValue);
  };

  return (
    <div onClick={onHide} className={`outlet ${customOutlet}`}>
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className={` ${customModal} modal accountAlreadyExistsModal`}
      >
        {closeIcon === true && (
          <div
            className={confirmDeal ? "closeDiv closeConfirmDeal" : "closeDiv"}
          >
            <img onClick={onHide} className="close" src={Close} alt="Close" />
          </div>
        )}
        <p>{title}</p>
        <span>{text}</span>

        {confirmDeal && (
          <div className="authCode-wrapper">
            <AuthCode
              // ref={AuthInputRef}
              allowedCharacters="numeric"
              length={4}
              onChange={handleOnChange}
            />
            {dealError && (
              <span className="error-verify">{y("incorrectPIN")}</span>
            )}
          </div>
        )}

        {btnText && (
          <ModalButton
            disabled={isLoading || (confirmDeal && pin.length !== 4)}
            onClick={(e: any) => {
              e.stopPropagation();
              if (confirmDeal) {
                onClick(pin);
              } else {
                onClick();
              }
            }}
          >
            {btnText}
          </ModalButton>
        )}

        {inputField && (
          <input type="text" value={btnText2} disabled={isLoading} />
        )}

        {showSecondBtn && (
          <ModalButton customClass="secondBtn" onClick={onClickSecondBtn}>
            {btnText2}
          </ModalButton>
        )}
      </div>
    </div>
  );
};

export default Modal;
